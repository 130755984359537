@use "styles/settings";
@use "styles/media-queries";
@use "~awesome-sass-easing/sass-easing";

//Buttons

@mixin reset-button {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}

@mixin button {
  @include reset-button;
}

@mixin scrollable {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: settings.$color-border;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border: 3px solid transparent;
  }
}

@mixin hover-float {
  transform: translateY(0px);
  box-shadow: settings.$box-shadow;
  transition: all 0.3s ease-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: settings.$box-shadow--extra;
  }
}

@mixin underlinedLink {
  border-bottom: 1px solid settings.$color-border--light;
  transition: all 0.3s ease-out;
  display: inline-block;

  &:hover {
    color: settings.$color-blue;
    border-color: settings.$color-blue--light;
  }
}

@mixin scrollableY {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: settings.$color-border;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border: 3px solid transparent;
  }
}

@mixin scrollableX {
  overflow-x: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 3px;
    background-color: settings.$color-border;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border: 3px solid transparent;
  }
}

@mixin hiddenScrollbar {
  &::-webkit-scrollbar-track {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
}

@mixin h1 {
  font-weight: 500;
  line-height: 1;

  @include media-queries.mq($until: tablet) {
    font-size: 31px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 37px;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 49px;
  }
}

@mixin h3 {
  font-weight: 500;
  line-height: 1;

  @include media-queries.mq($until: tablet) {
    font-size: 25px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 28px;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 32px;
  }
}

@mixin h4 {
  font-weight: 500;
  line-height: 1.25;

  @include media-queries.mq($until: tablet) {
    font-size: 21px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 21px;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 21px;
  }
}

@mixin h5 {
  font-weight: 500;

  @include media-queries.mq($until: tablet) {
    font-size: 16px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 16px;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 16px;
  }
}

@mixin bodyText {
  font-size: 21px;
  color: settings.$color-dark;
  line-height: 1.25;

  p {
    strong,
    b {
      font-weight: 500;
    }
    em {
      font-style: italic;
    }
    &:last-child {
      margin-bottom: 1em;
    }
  }
}

@mixin bodyText--small {
  @include bodyText;
  font-size: 19px;
}

@mixin bodyText--xSmall {
  @include bodyText;
  font-size: 16px;
}

@mixin pill {
  @include reset-button;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(settings.$color-main, 0.75);
  padding-left: settings.$gutter-width * 0.75;
  padding-right: settings.$gutter-width * 0.75;
  height: 32px;
  border-radius: 20px;
  white-space: nowrap;

  &:hover {
    color: settings.$color-main;
  }

  > div {
    font-size: 14px;
    line-height: 1;
    position: relative;
    top: -1px;
  }
}

@mixin pill--light {
  @include pill;
  background-color: settings.$color-light;
}
