@use "sass:math";

@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
  overflow: hidden;
  width: 100%;
}

.content {
  display: flex;
  max-width: settings.$grid-width--medium;
  margin: 0 auto;
  flex-direction: column;

  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 2;
    padding-bottom: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 3;
    padding-bottom: settings.$gutter-width * 3;
  }

  @include media-queries.mq($from: desktop) {
    padding-top: settings.$gutter-width * 3;
    padding-bottom: settings.$gutter-width * 3;
    padding-left: settings.$gutter-width * 3;
    padding-right: settings.$gutter-width * 3;
  }
}

.cell {
  display: flex;
  align-items: center;

  @include media-queries.mq($until: tablet) {
    flex-direction: column;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    flex-direction: row;
  }

  @include media-queries.mq($from: desktop) {
    flex-direction: row;
  }
}

.quotes {
  @include media-queries.mq($until: tablet) {
    max-width: 60px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    max-width: 80px;
  }

  @include media-queries.mq($from: desktop) {
    max-width: 80px;
  }
}

.quotation {
  font-weight: 500;
  max-width: settings.$grid-width--small;

  @include media-queries.mq($until: tablet) {
    font-size: 23px;
    line-height: 1.25;
    padding-top: settings.$gutter-width;
    padding-bottom: settings.$gutter-width;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 27px;
    line-height: 1.25;
    padding-top: settings.$gutter-width;
    padding-bottom: settings.$gutter-width;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 32px;
    line-height: 1.25;
    padding-top: math.div(settings.$gutter-width, 2);
    padding-bottom: math.div(settings.$gutter-width, 2);
  }
}

.attribution {
  color: settings.$color-main;
  font-weight: 300;

  @include media-queries.mq($until: tablet) {
    font-size: 21px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    font-size: 25px;
  }

  @include media-queries.mq($from: desktop) {
    font-size: 29px;
    padding-top: math.div(settings.$gutter-width, 2);
    padding-bottom: math.div(settings.$gutter-width, 2);
  }
}

.text {
  flex-grow: 1;

  @include media-queries.mq($until: tablet) {
    padding-left: settings.$gutter-width * 1;
    padding-right: settings.$gutter-width * 1;
    width: 100%;
    order: 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-left: settings.$gutter-width * 2;
    padding-right: settings.$gutter-width * 2;
    width: 66.666%;
    order: 1;
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 1;
    padding-right: settings.$gutter-width * 1;
    width: 66.666%;
    order: 1;
  }
}

.imageContainer {
  flex-grow: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-queries.mq($until: tablet) {
    padding-left: settings.$gutter-width * 3;
    padding-right: settings.$gutter-width * 3;
    width: 100%;
    order: 1;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-left: settings.$gutter-width * 2;
    padding-right: settings.$gutter-width * 2;
    width: 33.333%;
    order: 2;
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 2;
    padding-right: settings.$gutter-width * 2;
    width: 33.333%;
    order: 2;
  }
}

.imageWrapper {
  position: relative;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer {
  padding-top: settings.$gutter-width * 1;
  padding-bottom: settings.$gutter-width * 1;
  max-width: settings.$grid-width--small;
  display: flex;
  align-items: center;

  @include media-queries.mq($until: tablet) {
    padding-left: math.div(settings.$gutter-width, 4);
    padding-right: math.div(settings.$gutter-width, 4);
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-left: settings.$gutter-width * 1;
    padding-right: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 1;
    padding-right: settings.$gutter-width * 2;
    width: math.div(100%, 3) * 2;
  }
}

.pager {
  position: relative;

  @include media-queries.mq($until: tablet) {
    left: settings.$gutter-width;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
    left: math.div(settings.$gutter-width, -1);
  }
}

.pageControls {
  margin-left: auto;
}
