$grid-width--xSmall: 640px; // sm
$grid-width--small: 768px; // md
$grid-width--medium: 1280px; // xl
$grid-width: 1536px; // 2xl
$grid-width--xlarge: 1792px; // 3xl

$gutter-width: 16px; // 1rem / w-4

$color-blue: #0ea5e9; // sky-500
$color-blue--light: #7dd3fc; // sky-300
$color-blue--dark: #0284c7; // sky-600
$color-main: #111827; // gray-900
$color-dark: #6b7280; // gray-500
$color-mid: #9ca3af; // gray-400
$color-light: #f3f4f6; // gray-100
$color-xLight: #f9fafb; // gray-50

$color-border--light: #e5e7eb; // gray-200
$color-border: #d1d5db; // gray-300
$color-border--dark: #9ca3af; // gray-400

$color-background: $color-xLight;

$box-shadow:
  0px 1px 0px rgba($color-main, 0.025),
  0px 1px 3px rgba($color-main, 0.025);

$box-shadow--extra:
  0px 1px 0px rgba($color-main, 0.05),
  0px 0px 16px rgba($color-main, 0.075);

$border-radius: 8px; // rounded-lg
$border-radius--large: $gutter-width; // rounded-2xl
