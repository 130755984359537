@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "styles/media-queries";

.root {
}

.content {
  padding-top: math.div(settings.$gutter-width, 2);
  padding-bottom: math.div(settings.$gutter-width, 2);
  border-bottom: 1px solid settings.$color-border--light;
  color: settings.$color-mid;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
  }

  .root___dark & {
    color: settings.$color-mid;
    border-color: settings.$color-border--light;
  }

  .root___light & {
    color: #fff;
    border-color: rgba(#fff, 0.25);
  }
}
