@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
  position: relative;
}

.root___spaceAbove___none {
  padding-top: 0px;
}
.root___spaceAbove___single {
  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    padding-top: settings.$gutter-width * 3;
  }
}
.root___spaceAbove___double {
  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 3;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 3;
  }

  @include media-queries.mq($from: desktop) {
    padding-top: settings.$gutter-width * 4;
  }
}
.root___spaceAbove___triple {
  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 4;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 4;
  }

  @include media-queries.mq($from: desktop) {
    padding-top: settings.$gutter-width * 5;
  }
}

.root___spaceBelow___none {
  padding-bottom: 0px;
}
.root___spaceBelow___single {
  @include media-queries.mq($until: tablet) {
    padding-bottom: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-bottom: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    padding-bottom: settings.$gutter-width * 3;
  }
}
.root___spaceBelow___double {
  @include media-queries.mq($until: tablet) {
    padding-bottom: settings.$gutter-width * 3;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-bottom: settings.$gutter-width * 3;
  }

  @include media-queries.mq($from: desktop) {
    padding-bottom: settings.$gutter-width * 4;
  }
}
.root___spaceBelow___triple {
  @include media-queries.mq($until: tablet) {
    padding-bottom: settings.$gutter-width * 4;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-bottom: settings.$gutter-width * 4;
  }

  @include media-queries.mq($from: desktop) {
    padding-bottom: settings.$gutter-width * 5;
  }
}

.root___theme___transparent {
  background-color: transparent;
}

.root___theme___dark {
  background-color: #f6f6f6;
}
