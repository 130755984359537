@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "styles/media-queries";
@use "~awesome-sass-easing/sass-easing";

.root {
  position: relative;
  z-index: 2;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 4;
    padding-right: settings.$gutter-width * 4;
    padding-bottom: settings.$gutter-width * 4;

    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: settings.$gutter-width * 6;
      bottom: 0;
      background-color: settings.$color-light;
      z-index: 1;
    }
  }
}

.content {
  display: flex;
  position: relative;
  z-index: 1;

  @include media-queries.mq($until: tablet) {
    flex-direction: column;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    flex-direction: column;
  }

  @include media-queries.mq($from: desktop) {
    flex-direction: row;
    max-width: settings.$grid-width;
    margin: 0 auto;
  }
}

.navigation {
  display: flex;
  position: relative;

  @include media-queries.mq($until: tablet) {
    flex-wrap: nowrap;
    @include mixins.scrollableX;
    @include mixins.hiddenScrollbar;
    padding-left: settings.$gutter-width;
    padding-right: settings.$gutter-width;
    margin: 0 auto;
    margin-bottom: settings.$gutter-width;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    flex-wrap: nowrap;
    @include mixins.scrollableX;
    @include mixins.hiddenScrollbar;
    padding-left: settings.$gutter-width;
    padding-right: settings.$gutter-width;
    margin: 0 auto;
    margin-bottom: settings.$gutter-width * 2.5;
  }

  @include media-queries.mq($from: desktop) {
    position: absolute;
    left: 0;
    top: settings.$gutter-width * 3;
    width: calc(25% + #{settings.$gutter-width * 6});
    overflow: hidden;
    flex-direction: column;
    z-index: 1;
    border-radius: settings.$border-radius;
  }
}

.navigationItem {
  @include mixins.reset-button;

  @include media-queries.mq($until: tablet) {
    padding: math.div(settings.$gutter-width, 1.5)
      math.div(settings.$gutter-width, 1.5);
    border: 1px solid settings.$color-border;
    font-size: 16px;
    margin-right: -1px;
    color: settings.$color-mid;
    line-height: 1.25;
    text-align: center;

    &:first-child {
      border-radius: settings.$gutter-width 0 0 settings.$gutter-width;
    }

    &:last-child {
      border-radius: 0 settings.$gutter-width settings.$gutter-width 0;
    }
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding: settings.$gutter-width settings.$gutter-width * 1.5;
    border: 1px solid settings.$color-border;
    font-size: 16px;
    margin-right: -1px;
    color: settings.$color-mid;
    line-height: 1.25;
    text-align: center;

    &:first-child {
      border-radius: settings.$gutter-width * 2 0 0 settings.$gutter-width * 2;
    }

    &:last-child {
      border-radius: 0 settings.$gutter-width * 2 settings.$gutter-width * 2 0;
    }
  }

  @include media-queries.mq($from: desktop) {
    background-color: #d3d3da;
    font-size: 23px;
    font-weight: 500;
    padding: math.div(settings.$gutter-width, 2) settings.$gutter-width * 1.5;
    margin-bottom: 1px;
    height: settings.$gutter-width * 3;
    color: #fff;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-out;
    white-space: nowrap;

    @media (hover: hover) {
      &:hover {
        background-color: settings.$color-main;
      }
    }
  }
}

.navigationItem___isActive {
  @include media-queries.mq($until: tablet) {
    background-color: rgba(settings.$color-blue, 0.05);
    border: 1px solid settings.$color-blue;
    color: settings.$color-blue;
    z-index: 1;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    background-color: rgba(settings.$color-blue, 0.05);
    border: 1px solid settings.$color-blue;
    color: settings.$color-blue;
    z-index: 1;
  }

  @include media-queries.mq($from: desktop) {
    background-color: settings.$color-main;
  }
}

.navigationLabel {
}

.navigationIcon {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0px);
  transition: transform 0.3s ease-out;
  color: rgba(settings.$color-main, 0.125);

  @include media-queries.mq($until: tablet) {
    display: none;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    display: none;
  }

  @include media-queries.mq($from: desktop) {
  }

  .navigationItem:hover &,
  .navigationItem___isActive & {
    color: #fff;
  }

  .navigationItem:hover & {
    transform: translateX(math.div(settings.$gutter-width, 4));
  }
}

.item {
  padding-left: settings.$gutter-width * 1;
  padding-right: settings.$gutter-width * 1;

  @include media-queries.mq($until: tablet) {
    width: 100%;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    width: 100%;
  }

  @include media-queries.mq($from: desktop) {
    width: 75%;
    margin-left: auto;
  }
}

.image {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: settings.$border-radius settings.$border-radius 0 0;
}

.caption {
  background-color: #fff;
  font-weight: 300;

  @include media-queries.mq($until: tablet) {
    padding: settings.$gutter-width * 1;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding: settings.$gutter-width * 1;
  }

  @include media-queries.mq($from: desktop) {
    padding: settings.$gutter-width * 3;
  }
}

.heading {
  font-size: 29px;
  font-weight: 500;
  margin-bottom: math.div(settings.$gutter-width, 4);
}

.text {
  font-size: 21px;
}
