@use "styles/settings";
@use "styles/mixins";
@use "styles/media-queries";
@use "~awesome-sass-easing/sass-easing";

.root {
  opacity: 0;
  transition: opacity 1s ease-out;

  @include media-queries.mq($until: tablet) {
    padding-left: settings.$gutter-width * 1;
    padding-right: settings.$gutter-width * 1;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-left: settings.$gutter-width * 2;
    padding-right: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 3;
    padding-right: settings.$gutter-width * 3;
  }
}

.root___inView {
  opacity: 1;
}

.root___position___full {
  width: 100%;
}

.root___width___original {
  max-width: settings.$grid-width--small + settings.$gutter-width * 4;
}

.root___width___small {
  max-width: settings.$grid-width--xSmall;
}

.root___position___center {
  margin: 0 auto;
}

.image {
  position: relative;
  display: block;
  width: 100%;
  border-radius: settings.$border-radius;
}

.caption {
  position: relative;
  margin: 0 auto;
}
