@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  padding-top: math.div(settings.$gutter-width, 4);
  padding-bottom: math.div(settings.$gutter-width, 4);
  transition: opacity 0.3s ease-out;
  white-space: nowrap;

  &:hover {
    opacity: 0.5;
  }
}
