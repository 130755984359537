@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";
@use "styles/media-queries";

.root {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 75%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: settings.$color-light;
    z-index: 0;
  }
}

.carousel {
  width: 100%;
  position: relative;
  z-index: 1;
}

.slides {
  width: 100%;
  position: relative;
  z-index: 1;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
    padding-bottom: settings.$gutter-width * 2;
  }
}

.footer {
  display: flex;
  align-items: center;

  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 1;
    padding-bottom: settings.$gutter-width * 1;
    padding-left: settings.$gutter-width * 0.75;
    padding-right: settings.$gutter-width * 0.75;
    justify-content: flex-end;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 2;
    padding-bottom: settings.$gutter-width * 2;
    padding-left: settings.$gutter-width * 2;
    padding-right: settings.$gutter-width * 2;
    justify-content: flex-end;
  }

  @include media-queries.mq($from: desktop) {
    padding-top: settings.$gutter-width * 1;
    padding-bottom: settings.$gutter-width * 3;
    padding-left: settings.$gutter-width * 3;
    padding-right: settings.$gutter-width * 3;
    justify-content: center;
  }
}

.nav {
  display: flex;
  color: settings.$color-main;
  z-index: 1;
}

.navButton {
  @include mixins.reset-button;
  color: settings.$color-main;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: settings.$box-shadow;

  @include mixins.hover-float;

  @include media-queries.mq($until: tablet) {
    width: settings.$gutter-width * 2;
    height: settings.$gutter-width * 2;
    margin-left: math.div(settings.$gutter-width, 4);
    margin-right: math.div(settings.$gutter-width, 4);
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    width: settings.$gutter-width * 3;
    height: settings.$gutter-width * 3;
    margin-left: math.div(settings.$gutter-width, 2);
    margin-right: math.div(settings.$gutter-width, 2);
  }

  @include media-queries.mq($from: desktop) {
    width: settings.$gutter-width * 3;
    height: settings.$gutter-width * 3;
    margin-left: math.div(settings.$gutter-width, 2);
    margin-right: math.div(settings.$gutter-width, 2);
  }

  @media (hover: hover) {
    &:hover {
      background-color: settings.$color-main;
      color: #fff;
    }
  }
}

.navButton___isDisabled {
  opacity: 0.5;
  pointer-events: none;
}
