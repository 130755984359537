@use "sass:math";

@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-left: math.div(settings.$gutter-width, 2);
    padding-right: math.div(settings.$gutter-width, 2);
  }

  @include media-queries.mq($from: desktop) {
    padding-left: settings.$gutter-width * 1.5;
    padding-right: settings.$gutter-width * 1.5;
  }
}

.pagerButton {
  @include mixins.reset-button;
  opacity: 0.5;
  transition: opacity 1s ease-out;

  &:hover {
    opacity: 0.75;
  }

  @include media-queries.mq($until: tablet) {
    padding: math.div(settings.$gutter-width, 4);
    padding-left: 0;
    padding-right: 15px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding: math.div(settings.$gutter-width, 2);
  }

  @include media-queries.mq($from: desktop) {
    padding: math.div(settings.$gutter-width, 2);
  }
}

.pagerButton___isActive {
  opacity: 1;
}

.pagerCarat {
  height: 1.5px;

  @include media-queries.mq($until: tablet) {
    width: settings.$gutter-width * 1;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    width: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    width: settings.$gutter-width * 2;
  }

  .root___large & {
    @include media-queries.mq($until: tablet) {
      width: settings.$gutter-width * 1.5;
    }

    @include media-queries.mq($from: tablet, $until: desktop) {
      width: settings.$gutter-width * 3;
    }

    @include media-queries.mq($from: desktop) {
      width: settings.$gutter-width * 5;
    }
  }

  .root___dark & {
    background-color: settings.$color-main;
  }

  .root___light & {
    background-color: #fff;
  }
}
