@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.control {
  @include mixins.reset-button;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: color 0.3s ease-out;

  @include media-queries.mq($until: tablet) {
    width: settings.$gutter-width * 2;
    height: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    width: settings.$gutter-width * 2;
    height: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    width: settings.$gutter-width * 2;
    height: settings.$gutter-width * 3;
  }
}
