@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.block {
  position: relative;
}
